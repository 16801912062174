import css from 'styled-jsx/css';

export default css`
  .header {
    position: sticky;
    top: -30px;
    z-index: 2;
  }
  .header__menu-wrap-center-logo {
    background-image: var(--logo-login);
    height: 50px;
    background-repeat: no-repeat;
    width: 100%;
    max-width: 150px;
    background-size: contain;
    background-position: center;
    cursor: pointer;
  }
  .counter {
    position: absolute;
    top: -6px;
    right: -3px;
    background: var(--error);
    width: 18px;
    height: 18px;
    border-radius: 50px;
    font-size: 12px;
    color: white;
    font-weight: 600;
  }
  .wrapper-social-header {
    border-bottom: var(--line);
    background: var(--background-subheader);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    position: relative;
    z-index: 3;
  }
  :global(.social-header) {
    max-width: 1200px;
    margin: auto;
    padding: 8px 16px;
  }
  .top-banner {
    background: var(--banner-top);
    color: #fff;
    font-size: 14px;
    padding: 0.3em;
  }
  .cart-icon {
    cursor: pointer;
  }
  .cart-icon :global(.icon) {
    font-size: 26px;
  }
  @media only screen and (min-width: 768px) {
    :global(.social-header) {
      max-width: 1200px;
      margin: auto;
      padding: 8px;
    }
  }
  a {
    cursor: pointer;
    text-decoration: underline;
  }
`;
