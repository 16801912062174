function Loader() {
  return (
    <div className="relative">
      <div className="main-loader">
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle
              className="loader-path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="#FFF"
              strokeWidth="4"
            />
          </svg>
        </div>
      </div>
      <style jsx>{`
        .main-loader {
          position: absolute !important;
          right: 0;
          z-index: 9000 !important;
        }
        .main-loader .loader {
          position: relative;
          margin: 0px auto;
          width: 20px;
          height: 20px;
        }
        .main-loader .loader:before {
          content: '';
          display: block;
          padding-top: 100%;
        }
        .circular-loader {
          -webkit-animation: rotate 1s linear infinite;
          animation: rotate 1s linear infinite;
          height: 100%;
          -webkit-transform-origin: center center;
          -ms-transform-origin: center center;
          transform-origin: center center;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          margin: auto;
        }
        .loader-path {
          -webkit-animation: dash 1s ease-in-out infinite,
            color 6s ease-in-out infinite;
          animation: dash 1s ease-in-out infinite, color 6s ease-in-out infinite;
        }

        @-webkit-keyframes rotate {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes rotate {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @-webkit-keyframes dash {
          0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
          }
        }
        @keyframes dash {
          0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
          }
          50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35;
          }
          100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124;
          }
        }
        @-webkit-keyframes color {
          0% {
            stroke: #fff;
          }
          40% {
            stroke: #fff;
          }
          66% {
            stroke: #fff;
          }
          80%,
          90% {
            stroke: #fff;
          }
        }
        @keyframes color {
          0% {
            stroke: #fff;
          }
          40% {
            stroke: #fff;
          }
          66% {
            stroke: #fff;
          }
          80%,
          90% {
            stroke: #fff;
          }
        }
        .relative {
          position: relative;
        }
      `}</style>
    </div>
  );
}

export default Loader;
