import useResponsive from 'hooks/useResponsive';
import useTranslation from 'hooks/useTranslation';
import Icon from '../../utils/Icon';
import style from './index.style';
import { useCallback } from 'react';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

function Header({ cart, username, userCatalogId, referralCode, products }) {
  const { isMobile } = useResponsive();
  const router = useRouterWithLang();
  const { TRANSLATIONS } = useTranslation();
  const goToCatalog = useCallback(() => {
    window.open(
      `/register?redirectUrl=${encodeURIComponent(
        `/product`
      )}&channelcode=affiliation_${referralCode}`,
      '_blank',
      'noopener,noreferrer'
    );
  }, [referralCode]);

  return (
    <div className="header">
      <div className={'top-banner'}>
        <div className={'d-f g-10 ai-c jc-c ta-c'}>
          <a onClick={goToCatalog}>{TRANSLATIONS.shop.preHeaderText}</a>
        </div>
      </div>
      <div className="wrapper-social-header ">
        <div className="social-header d-f jc-sb ai-c">
          <div
            className="header__menu-wrap-center-logo ai-c d-f"
            onClick={goToCatalog}
          />
          <div
            className="relative cart-icon"
            onClick={() =>
              router.push(`/shop/${username}/${userCatalogId}/checkout`)
            }
          >
            <Icon type="shopping-cart" />
            {cart?.length > 0 && (
              <span className="d-f jc-c ai-c counter">{cart?.length}</span>
            )}
          </div>
        </div>
      </div>
      <style jsx>{style}</style>
    </div>
  );
}

export default Header;
