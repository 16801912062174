import style from 'components/utils/button.style';
import Icon from 'components/utils/Icon';

import Loader from './Loader';

function Button({
  type = 'button',
  label,
  children,
  onClick,
  className,
  disabled,
  iconAfter,
  iconBefore,
  loading,
  img,
}) {
  return (
    <>
      <button
        type={type}
        className={
          'button ' +
          className +
          (loading ? ' loading' : '') +
          (disabled ? ' disabled' : '') +
          (iconBefore ? ' iconBefore' : '') +
          (iconAfter ? ' iconAfter' : '')
        }
        onClick={onClick}
        // disabled={disabled}
      >
        {img && <img src={img} alt="button-img" />}
        {loading && <Loader />}
        {iconBefore ? <Icon type={iconBefore} /> : ''}
        {children || label}
        {iconAfter ? <Icon type={iconAfter} /> : ''}
      </button>
      <style jsx>{style}</style>
    </>
  );
}

export default Button;
